import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import BackToTop from "./elements/back-top";
import PageScrollTop from "./elements/page-scroll-top";
import Index3 from "./pages/index-3";
import About1 from "./pages/about-1";
import Portfolio from "./pages/portfolio";
import StudentWork from "./pages/student-work";
import News0 from "./pages/news-0";
import News1 from "./pages/news-1";
import News2 from "./pages/news-2";
import News3 from "./pages/news-3";
import News4 from "./pages/news-4";
import News5 from "./pages/news-5";
import News6 from "./pages/news-6";
import News7 from "./pages/news-7";
import IOAIIntroduction from "./pages/ioai-introduction";
import IOAIResource from "./pages/ioai-resource";
import IOAIApplication from "./pages/ioai-application";
import IOAIJourney from "./pages/ioai-journey";
import AOAITeam from "./pages/aoai-team";
import IOAISponsor from "./pages/aoai-sponsor";
import CoursesDetails from "./pages/courses-details";
import Contact2 from "./pages/contact-2";


class Markup extends Component {
	componentDidMount() {
		
	} 
	render() {
		return (
			<>
				<BrowserRouter basename={"/"}>
					<Switch>
						<Route path="/" exact component={Index3} />
						<Route path="/courses-details" exact component={CoursesDetails} />
						<Route path="/news-0" exact component={News0} />
						<Route path="/news-1" exact component={News1} />
						<Route path="/news-2" exact component={News2} />
						<Route path="/news-3" exact component={News3} />
						<Route path="/news-4" exact component={News4} />
						<Route path="/news-5" exact component={News5} />
						<Route path="/news-6" exact component={News6} />
						<Route path="/news-7" exact component={News7} />
						<Route path="/ioai-introduction" exact component={IOAIIntroduction} />
						<Route path="/ioai-resource" exact component={IOAIResource} />
						<Route path="/aoai-sponsor" exact component={IOAISponsor} />
						<Route path="/ioai-application" exact component={IOAIApplication} />
						<Route path="/ioai-journey" exact component={IOAIJourney} />
						<Route path="/aoai-team" exact component={AOAITeam} />
						<Route path="/portfolio" exact component={Portfolio} />
						<Route path="/student-work" exact component={StudentWork} />
						<Route path="/about" exact component={About1} />
						<Route path="/contact" exact component={Contact2} />
					</Switch>		
					<div className="section-area section-sp3">
						<div className="col-md-12 heading-bx style1 text-center">
							<b>© Leading Coding</b>
							<br/>
							Website Content Design and Development by L&C Information Technology and Education
						</div>
					</div>
					<PageScrollTop />
				</BrowserRouter>
				<BackToTop />
			</>
		);
	}
}

export default Markup;
